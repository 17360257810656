@import '~antd/dist/antd.less';
@import './colors.less';
@import './colors-in-use.less';
/**
 * TODO: Find a better way to share variables from colors.ts between
 * *.less and *.ts files. Until then we keep a copy of the files in this folder.
 * Antd variables file to be overwritten: 
 * https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */

///////////////////////////////////////////////////////////////////////////////////
// Variables overrides - use colors.less and colors-in-use.less variables for overriding
///////////////////////////////////////////////////////////////////////////////////
// -------- Colors -----------
@primary-color: @colorBase-Brand;
@info-color: @colorFeedback-Info;
@success-color: @colorFeedback-Success;
@processing-color: @colorFeedback-Info;
@error-color: @colorFeedback-Danger;
@warning-color: @colorFeedback-Warning;
@white: @colorBase-White;
@black: @colorBase-Black;
@component-background: @colorBase-White;

// -------- Font ------------
@font-size-base: 16px;

// -------- Layout -----------
@layout-body-background: @colorBase-White;

// -------- Link -----------
@link-color: @colorTypography-Link;
// @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
// @link-active-color: color(~`colorPalette('@{link-color}', 7) `);

// -------- Border color -----------
@border-color-base: @colorBorder-BorderPrimary; // base border outline a component
@border-color-split: @colorBorder-BorderPrimary; // split border inside a component

// -------- Border -------------
@border-primary: 1px solid @colorBorder-BorderPrimary;
@border-secondary: 1px solid @colorBorder-BorderSecondary;

///////////////////////////////////////////////////////////////////////////////////
// Genral overrides
///////////////////////////////////////////////////////////////////////////////////
html {
  // This is for the after glow effect when i.e. clicking a button
  --antd-wave-shadow-color: @colorBase-Blue;
}
@font-family: 'BasierRegular', apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

///////////////////////////////////////////////////////////////////////////////////
// Component overrides
///////////////////////////////////////////////////////////////////////////////////
/**
 * Avatar
 */
.ant-avatar-square {
  // `%` so it can scale proportionally with different sizes
  border-radius: 16%;
}

/**
 * Button
 */
// -------- Base -------------
@btn-font-weight: 400;
@btn-border-radius-base: 4px;
@btn-border-width: 0px;
@btn-height-base: 48px;

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-default-color: @colorTypography-UI;
@btn-default-bg: transparent;
@btn-default-border: @border-color-base;

.ant-btn {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  height: auto;
  // padding: 12px 24px;
}

// Radio buttons
@radio-button-bg: white;
@radio-button-checked-bg: white;
// @radio-button-color: @colorTypography-Heading;
// @radio-button-hover-color: @primary-5;
@radio-button-active-color: @colorTypography-Heading;
// @radio-disabled-button-checked-bg: tint(@black, 90%);
// @radio-disabled-button-checked-color: @disabled-color;
// @radio-wrapper-margin-right: 8px;

// -------- base -------------
// @btn-font-weight: 400;
// @btn-border-radius-base: @border-radius-base;
// @btn-border-radius-sm: @border-radius-base;
// @btn-border-width: @border-width-base;
// @btn-border-style: @border-style-base;
// @btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
// @btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
// @btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
// @btn-line-height: @line-height-base;
// -------- primary -------------
@btn-primary-color: #fff;
@btn-primary-bg: @colorButton-Primary;
// -------- default -------------
@btn-default-color: #fff;
@btn-default-bg: @colorButton-Secondary;
// @btn-default-border: @border-color-base;
// -------- danger -------------
// @btn-danger-color: #fff;
// @btn-danger-bg: @error-color;
// @btn-danger-border: @error-color;
// -------- disable -------------
// @btn-disable-color: @disabled-color;
// @btn-disable-bg: @disabled-bg;
// @btn-disable-border: @border-color-base;
// -------- default-ghost -------------
// @btn-default-ghost-color: @component-background;
// @btn-default-ghost-bg: transparent;
// @btn-default-ghost-border: @component-background;
// -------- sizes -------------
// @btn-font-size-lg: @font-size-lg;
// @btn-font-size-sm: @font-size-base;
// @btn-padding-horizontal-base: @padding-md - 1px;
// @btn-padding-horizontal-lg: @btn-padding-horizontal-base;
// @btn-padding-horizontal-sm: @padding-xs - 1px;
// -------- height -------------
// @btn-height-base: @height-base;
// @btn-height-lg: @height-lg;
// @btn-height-sm: @height-sm;
// -------- circle -------------
// @btn-circle-size: @btn-height-base;
// @btn-circle-size-lg: @btn-height-lg;
// @btn-circle-size-sm: @btn-height-sm;
// -------- square -------------
// @btn-square-size: @btn-height-base;
// @btn-square-size-lg: @btn-height-lg;
// @btn-square-size-sm: @btn-height-sm;
// @btn-square-only-icon-size: @font-size-base + 2px;
// @btn-square-only-icon-size-sm: @font-size-base;
// @btn-square-only-icon-size-lg: @btn-font-size-lg + 2px;
// -------- group -------------
// @btn-group-border: @primary-5;
// -------- link -------------
// @btn-link-hover-bg: transparent;
// @btn-text-hover-bg: rgba(0, 0, 0, 0.018);

// Form
// ---
@form-item-label-font-size: 13px;

/**
 * Input
 */
// @input-padding-horizontal: 0;
// @input-padding-vertical-base: 0;
// .ant-input {
//   border: 0;

//   &:focus {
//     box-shadow: none;
//   }
// }

// Menu
// ---
// @menu-inline-toplevel-item-height: 40px;
// @menu-item-height: 40px;
// @menu-item-group-height: @line-height-base;
// @menu-collapsed-width: 80px;
@menu-bg: @colorBackground-Secondary;
// @menu-popup-bg: @component-background;
// @menu-item-color: @text-color;
@menu-inline-submenu-bg: @colorBase-Black;
@menu-highlight-color: @colorBase-Black; // selected indicator bg
// @menu-highlight-danger-color: @error-color;
@menu-item-active-bg: @color-Grey900; // selected bg
// @menu-item-active-danger-bg: @red-1;
// @menu-item-active-border-width: 3px;
// @menu-item-group-title-color: @text-color-secondary;
// @menu-item-vertical-margin: 4px;
// @menu-item-font-size: @font-size-base;
// @menu-item-boundary-margin: 8px;
// @menu-item-padding: 0 20px;
// @menu-horizontal-line-height: 46px;
// @menu-icon-margin-right: 10px;
// @menu-icon-size: @menu-item-font-size;
// @menu-icon-size-lg: @font-size-lg;
// @menu-item-group-title-font-size: @menu-item-font-size;

// dark theme
// @menu-dark-color: @text-color-secondary-dark;
// @menu-dark-danger-color: @error-color;
// @menu-dark-bg: @layout-header-background;
// @menu-dark-arrow-color: #fff;
// @menu-dark-inline-submenu-bg: #000c17;
// @menu-dark-highlight-color: #fff;
// @menu-dark-item-active-bg: @primary-color;
// @menu-dark-item-active-danger-bg: @error-color;
// @menu-dark-selected-item-icon-color: @white;
// @menu-dark-selected-item-text-color: @white;
// @menu-dark-item-hover-bg: transparent;

// Select
// @select-border-color: @border-color-base;
// @select-item-selected-color: @text-color;
// @select-item-selected-font-weight: 600;
// @select-dropdown-bg: @colorBase-Brand;
// @select-item-selected-bg: @colorBase-Brand;
// @select-item-active-bg: transparent;
// @select-dropdown-vertical-padding: @dropdown-vertical-padding;
// @select-dropdown-font-size: @dropdown-font-size;
// @select-dropdown-line-height: @dropdown-line-height;
// @select-dropdown-height: 48px;
// @select-background: @colorBase-Brand;
// @select-clear-background: @select-background;
// @select-selection-item-bg: @colorBase-Brand;
// @select-selection-item-border-color: @border-color-split;
// @select-single-item-height-lg: 40px;
// @select-multiple-item-height: @input-height-base - @input-padding-vertical-base * 2; // Normal 24px
// @select-multiple-item-height-lg: 32px;
// @select-multiple-item-spacing-half: ceil((@input-padding-vertical-base / 2));
// @select-multiple-disabled-background: @input-disabled-bg;
// @select-multiple-item-disabled-color: #bfbfbf;
// @select-multiple-item-disabled-border-color: @select-border-color;

/**
 * Modal
 */
// @modal-header-bg: transparent;
// @modal-header-close-size: 48px;
// @modal-header-border-color-split: transparent;
// @modal-footer-padding-vertical: 0;
// @modal-footer-padding-horizontal: 0;
@modal-border-radius: 8px;
.ant-modal-content {
  border-radius: 8px;
}


// Table
// --
@table-bg: @color-White000;
// @table-header-bg: @background-color-light;
// @table-header-color: @heading-color;
// @table-header-sort-bg: @background-color-base;
// @table-body-sort-bg: #fafafa;
// @table-row-hover-bg: @background-color-light;
// @table-selected-row-color: inherit;
// @table-selected-row-bg: @primary-1;
// @table-body-selected-sort-bg: @table-selected-row-bg;
// @table-selected-row-hover-bg: darken(@table-selected-row-bg, 2%);
// @table-expanded-row-bg: #fbfbfb;
// @table-padding-vertical: 16px;
// @table-padding-horizontal: 16px;
// @table-padding-vertical-md: (@table-padding-vertical * 3 / 4);
// @table-padding-horizontal-md: (@table-padding-horizontal / 2);
// @table-padding-vertical-sm: (@table-padding-vertical / 2);
// @table-padding-horizontal-sm: (@table-padding-horizontal / 2);
// @table-border-color: @border-color-split;
// @table-border-radius-base: @border-radius-base;
// @table-footer-bg: @background-color-light;
// @table-footer-color: @heading-color;
// @table-header-bg-sm: @table-header-bg;
// @table-font-size: @font-size-base;
// @table-font-size-md: @table-font-size;
// @table-font-size-sm: @table-font-size;
// // Sorter
// // Legacy: `table-header-sort-active-bg` is used for hover not real active
// @table-header-sort-active-bg: darken(@table-header-bg, 3%);
// // Filter
// @table-header-filter-active-bg: darken(@table-header-sort-active-bg, 5%);
// @table-filter-btns-bg: inherit;
// @table-filter-dropdown-bg: @component-background;
// @table-expand-icon-bg: @component-background;
// @table-selection-column-width: 32px;
// // Sticky
// @table-sticky-scroll-bar-bg: fade(#000, 35%);
// @table-sticky-scroll-bar-radius: 4px;

// Pagination
// ---
@pagination-item-bg: @color-White000;
// @pagination-item-size: @height-base;
// @pagination-item-size-sm: 24px;
// @pagination-font-family: @font-family;
// @pagination-font-weight-active: 500;
@pagination-item-bg-active: @color-White000;
@pagination-item-link-bg: @color-White000;
// @pagination-item-disabled-color-active: @white;
// @pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);
@pagination-item-input-bg: @color-White000;
// @pagination-mini-options-size-changer-top: 0px;

/**
 * Tooltip
 */
// @tooltip-arrow-width: 0;
// @tooltip-color: @color-Fog20;
// @tooltip-bg: @color-Viridian5;

// .ant-tooltip-inner {
//   padding: 6px 12px;
//   font-size: 12px;
//   line-height: 20px;
//   font-weight: 500;
// }

/**
 * Popover
 */
// .ant-popover-inner {
//   background-color: @color-Fog20;
//   border-radius: 8px;
//   .ant-popover-inner-content {
//     padding: 0;
//   }
// }
